import { useEffect, useState, useRef } from 'react';
import useFetch from './../../useFetch';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
    document.title = "Dashboard | CellyCash";
    
    const backgroundColor = ['#de760b', '#103dbf', '#06897c', '#058343', '#ad05a0', '#ad1405', '#f31313'];
    
    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/dashboard');
    
    return (
        <div className="container-fluid">
            <div className="row">
                {/* <div className="col-xl-3 col-sm-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <Link to="/orders"><div className="small font-weight-bold text-uppercase mb-1" style={{color: '#058343'}}>Orders</div></Link>
                            <div className="h5 mb-1 font-weight-bold text-gray-900">{ response?.order?.total ?? '-' }</div>
                            <Link to="/orders?status=Pending"><span className="badge badge-table badge-danger font-weight-bold">Pending: { response?.order?.pending ?? '-' }</span></Link>
                            <div className="mb-0 text-gray-900">Today: { response?.order?.today ?? '-' }</div>
                            <div className="mb-0 text-gray-900">7 days: { response?.order?.week ?? '-' }</div>
                        </div>
                    </div>
                </div> */}

                <div className="col-xl-3 col-sm-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <Link to="/instant-cash-requests"><div className="small font-weight-bold text-uppercase mb-1" style={{color: '#06897c'}}>Instant Cash</div></Link>
                            <div className="h5 mb-1 font-weight-bold text-gray-900">{ response?.instantCash?.total ?? '-' }</div>
                            <Link to="/instant-cash-requests?status=Pending"><span className="badge badge-table badge-danger font-weight-bold">Pending: { response?.instantCash?.pending ?? '-' }</span></Link>
                            <div className="mb-0 text-gray-900">Today: { response?.instantCash?.today ?? '-' }</div>
                            <div className="mb-0 text-gray-900">7 days: { response?.instantCash?.week ?? '-' }</div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-xl-3 col-sm-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <Link to="/repair-requests"><div className="small font-weight-bold text-uppercase mb-1" style={{color: '#103dbf'}}>Phone Repair</div></Link>
                            <div className="h5 mb-1 font-weight-bold text-gray-900">{ response?.repair?.total ?? '-' }</div>
                            <Link to="/repair-requests?status=Pending"><span className="badge badge-table badge-danger font-weight-bold">Pending: { response?.repair?.pending ?? '-' }</span></Link>
                            <div className="mb-0 text-gray-900">Today: { response?.repair?.today ?? '-' }</div>
                            <div className="mb-0 text-gray-900">7 days: { response?.repair?.week ?? '-' }</div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <Link to="/buy-phones"><div className="small font-weight-bold text-uppercase mb-1" style={{color: '#de760b'}}>Ads</div></Link>
                            <div className="h5 mb-1 font-weight-bold text-gray-900">{ response?.ad?.total ?? '-' }</div>
                            <span className="badge badge-table badge-success font-weight-bold">Sold: { response?.ad?.sold ?? '-' }</span>
                            <div className="mb-0 text-gray-900">Today: { response?.ad?.today ?? '-' }</div>
                            <div className="mb-0 text-gray-900">7 days: { response?.ad?.week ?? '-' }</div>
                        </div>
                    </div>
                </div> */}
            {/* </div>

            <div className="row">
                <div className="col-xl-4 col-sm-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold" style={{color: '#058343'}}>Order Stats</h6>
                        </div>
                        <div className="card-body">
                            <Doughnut data={{
                                labels: [
                                    `Pending (${response?.order?.pending ?? 0})`,
                                    `On Hold (${response?.order?.onHold ?? 0})`,
                                    `In Progress (${response?.order?.inProgress ?? 0})`,
                                    `Completed (${response?.order?.completed ?? 0})`,
                                    `Not Interested (${response?.order?.notInterested ?? 0})`,
                                    `Rejected (${response?.order?.rejected ?? 0})`,
                                    `Fake (${response?.order?.fake ?? 0})`,
                                ],
                                legend: {
                                    position: 'right',
                                },
                                datasets: [
                                    {
                                        data: [
                                            response?.order?.pending ?? 0,
                                            response?.order?.onHold ?? 0,
                                            response?.order?.inProgress ?? 0,
                                            response?.order?.completed ?? 0,
                                            response?.order?.notInterested ?? 0,
                                            response?.order?.rejected ?? 0,
                                            response?.order?.fake ?? 0,
                                        ],
                                        backgroundColor,
                                        cutout: '70%',
                                        hoverOffset: 20
                                    },
                                ],
                                }} />
                        </div>
                    </div>
                </div> */}
                <div className="col-xl-9 col-sm-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold" style={{color: '#058343'}}>Instant Cash Stats</h6>
                        </div>
                        <div className="card-body">
                            <Doughnut 
                            height={300}
                            options={{ maintainAspectRatio: false }}
                            data={{
                                labels: [
                                    `Pending (${response?.instantCash?.pending ?? 0})`,
                                    `On Hold (${response?.instantCash?.onHold ?? 0})`,
                                    `In Progress (${response?.instantCash?.inProgress ?? 0})`,
                                    `Completed (${response?.instantCash?.completed ?? 0})`,
                                    `Not Interested (${response?.instantCash?.notInterested ?? 0})`,
                                    `Rejected (${response?.instantCash?.rejected ?? 0})`,
                                    `Fake (${response?.instantCash?.fake ?? 0})`,
                                ],
                                datasets: [
                                    {
                                        data: [
                                            response?.instantCash?.pending ?? 0,
                                            response?.instantCash?.onHold ?? 0,
                                            response?.instantCash?.inProgress ?? 0,
                                            response?.instantCash?.completed ?? 0,
                                            response?.instantCash?.notInterested ?? 0,
                                            response?.instantCash?.rejected ?? 0,
                                            response?.instantCash?.fake ?? 0,
                                        ],
                                        backgroundColor,
                                        cutout: '70%',
                                        hoverOffset: 20
                                    },
                                ],
                                }} />
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-4 col-sm-6">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold" style={{color: '#058343'}}>Phone Repair Stats</h6>
                        </div>
                        <div className="card-body">
                            <Doughnut data={{
                                labels: [
                                    `Pending (${response?.repair?.pending ?? 0})`,
                                    `On Hold (${response?.repair?.onHold ?? 0})`,
                                    `In Progress (${response?.repair?.inProgress ?? 0})`,
                                    `Completed (${response?.repair?.completed ?? 0})`,
                                    `Not Interested (${response?.repair?.notInterested ?? 0})`,
                                    `Rejected (${response?.repair?.rejected ?? 0})`,
                                    `Fake (${response?.repair?.fake ?? 0})`,
                                ],
                                datasets: [
                                    {
                                        data: [
                                            response?.repair?.pending ?? 0,
                                            response?.repair?.onHold ?? 0,
                                            response?.repair?.inProgress ?? 0,
                                            response?.repair?.completed ?? 0,
                                            response?.repair?.notInterested ?? 0,
                                            response?.repair?.rejected ?? 0,
                                            response?.repair?.fake ?? 0,
                                        ],
                                        backgroundColor,
                                        cutout: '70%',
                                        hoverOffset: 20
                                    },
                                ],
                                }} />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
 
export default Dashboard;