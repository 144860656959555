import { Form } from "react-bootstrap";

const Select = ({ label, name, value, options, setter, required }) => {
    return ( 
        <>
            <Form.Label className="mb-1">{ label }</Form.Label>
            <Form.Select 
                name={ name } 
                className="form-control"
                value={ value }
                onChange={ (e) => setter(e.target.value) }
                required={required ? true : false}
                >
                { options && options.map((value) => (
                    <option key={value.value} value={ value.value }>{ value.text }</option>
                )) }
            </Form.Select>
        </>
     );
}
 
export default Select;