import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Input from "./Forms/Input";

const NotFound = () => {
    document.title = "Not Found | CellyCash";
    
    return (
        <div className="container-fluid">
            <div className="text-center">
                <img src="/404.svg" style={{maxWidth: '400px'}} />
            </div>
        </div>
    );
}
 
export default NotFound;