import { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Dropdown, Image, Table } from 'react-bootstrap';

const Index = () => {
    document.title = "Repair Mobiles | CellyCash";
    
    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/repair-mobiles');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Keyword',
                type: 'input',
                name: 'keyword',
            },
        ],
        sort: {
            label: 'Sort By',
            name: 'sort',
            values: [
                { text: 'Brand asc, mobile asc', value: 'bama' },
                { text: 'Brand desc, mobile asc', value: 'bdma' },
                { text: 'Brand asc, mobile desc', value: 'bamd' },
                { text: 'Brand desc, mobile desc', value: 'bdmd' },
                { text: 'Title ascending', value: 'title_asc' },
                { text: 'Title descending', value: 'title_desc' },
            ]
        }
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.mobiles?.data ?? []);
        setMeta(response?.mobiles?.meta ?? []);
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    return (
        <div className="container-fluid">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Repair Mobiles</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><Link to="create">Add New</Link></h6>
                    <div className="clearfix"></div>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{width: '100px'}}>Order</th>
                                            <th style={{minWidth: '140px'}}>Brand</th>
                                            <th style={{minWidth: '140px'}}>Name</th>
                                            <th style={{width: '1px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item) => (
                                            <tr key={ item.id }>
                                                <td>{ item.brand_order }-{ item.order }</td>
                                                <td>{ item.brand }</td>
                                                <td>{ item.name }</td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to={`detail/${item.id}`}>View</Dropdown.Item>
                                                            <Dropdown.Item as={Link} to={`edit/${item.id}`}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'repair-mobiles/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;