import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";

const Paginate = ({ url, currentPage, pageCount, ...params }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handlePageClick = (e) => {
        var newUrl = new URL(url);

        searchParams.set('page', e.selected+1);
        
        newUrl.searchParams.set('page', e.selected+1);
        params.setCurrentPage(e.selected);
        
        params.setUrl(newUrl)
    }

    return ( 
        <ReactPaginate
            onPageChange={handlePageClick}
            forcePage={currentPage}
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            previousLabel="&laquo; Previous"
            nextLabel="Next &raquo;"
            breakLabel="..."
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            breakClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            />
     );
}
 
export default Paginate;