import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Button, Table } from 'react-bootstrap';

const Detail = () => {
    document.title = "Order Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [successStatus, setSuccessStatus] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/orders/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
        }
    }, [response])

    const handleStatus = (e, status) => {
        e.preventDefault();

        setIsLoadingStatus(true);
        setSuccessStatus(null);
        setErrorStatus(null);

        fetch(process.env.REACT_APP_BASE_API_URL+'/orders/'+id+'/change-status/'+status, {
            method: 'POST',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while changing status, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingStatus(false);

            if (data?.status == 'success')
            {
                setSuccessStatus(data.message);
                setData(data.data);
            }
            else
            {
                setErrorStatus(data.message);
            }
        })
        .catch(error => {
            setIsLoadingStatus(false);
            setErrorStatus(error.message);
        })
    }
    
    return (
        <div className="container-fluid"> 
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Order Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="row">
                                <div className="order-xl-2 order-lg-2 col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <Table responsive hover style={{border: '1px solid #dee2e6'}}>
                                        <tbody>
                                            { data?.items?.map((item) => (
                                                <tr>
                                                    <td style={{width: '80px', minWidth: '80px'}}>
                                                        <img src={item.cover} className="img-fluid" style={{height: '70px', width: '70px', objectFit: 'cover'}} />
                                                    </td>
                                                    <td className="align-middle">
                                                        <span className="badge badge-success" style={{backgroundColor: item.type == 'Certified Phone' ? '#0E9877' : '#e2a71d', display: 'block', fontSize: '12px !important', maxWidth: '120px', lineHeight: '1.3', marginBottom: '3px'}}>{item.type}</span>
                                                        <p className="m-0">
                                                            {item.type == 'Certified Phone' ? 
                                                                <Link to={`/certified-phones/detail/${item.item_id}`}>{item.name}</Link> : 
                                                                <Link to={`/accessories/detail/${item.item_id}`}>{item.name}</Link>
                                                            }
                                                        </p>
                                                        <span className="d-block td-price font-weight-600">Rs. {item.price}</span>
                                                    </td>
                                                    <td className="align-middle text-right">
                                                        <span className="d-block">x {item.quantity}</span>
                                                        <span className="d-block td-price font-weight-600">Rs. {item.subtotal}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="6">
                                                    <h3 className="mb-0 text-right font-weight-600 text-green" style={{fontSize: '26px'}}>Total: Rs. {data?.total}</h3>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </div>
					            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12 mb-4">
                                    <div className="mb-2">
                                        <label className="mb-1">Change Status</label>
                                        <p className="font-weight-bold mb-0">
                                            <Button variant="warning" onClick={(e) => handleStatus(e, 'pending')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Pending</Button>
                                            <Button variant="primary" onClick={(e) => handleStatus(e, 'On Hold')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">On Hold</Button>
                                            <Button variant="info" onClick={(e) => handleStatus(e, 'In Progress')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">In Progress</Button>
                                            <Button variant="success" onClick={(e) => handleStatus(e, 'Completed')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Completed</Button>
                                            <Button variant="danger" onClick={(e) => handleStatus(e, 'Not Interested')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Not Interested</Button>
                                            <Button variant="danger" onClick={(e) => handleStatus(e, 'Rejected')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Rejected</Button>
                                            <Button variant="danger" onClick={(e) => handleStatus(e, 'Fake')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Fake</Button>
                                        </p>
                                        { successStatus && <div className="alert-msg-success flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ successStatus }</strong></h3></div> }
                                        { errorStatus && <div className="alert-msg-danger flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ errorStatus }</strong></h3></div> }
                                    </div>

                                    <Table responsive hover style={{border: '1px solid #dee2e6'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{width: '150px'}}>Status</td>
                                                <th className="font-weight-bold">
                                                    <span className={`badge badge-table badge-${data?.status_badge} mr-3`}>{ data?.status }</span>
                                                    {data?.status_changed_at ? (<div className="badge badge-success d-inline-block mt-1" style={{whiteSpace: 'initial', lineHeight: '1.2'}}>Changed: {data?.status_changed_at}</div>) : null}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Order Date</td>
                                                <th className="font-weight-bold">{data?.created_at}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Order Number</td>
                                                <th className="font-weight-bold">{data?.order_number}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Logged User</td>
                                                <th className="font-weight-bold">
                                                    {data?.user ? (
                                                        <>
                                                            <div>{data?.user.name}</div>
                                                            {data?.user?.phone ? <p className="font-weight-bold mb-0"><a href={`tel:${data?.user?.phone}`}>{data?.user?.phone}</a></p> : null}
                                                            {data?.user?.email ? <p className="font-weight-bold mb-0"><a href={`mailto:${data?.user?.email}`}>{data?.user?.email}</a></p> : null}
                                                        </>
                                                    ) : (
                                                        <span className="text-danger">GUEST</span>
                                                    )}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Name</td>
                                                <th className="font-weight-bold">{data?.name}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Phone</td>
                                                <th className="font-weight-bold"><a href={`tel:${data?.phone}`}>{data?.phone}</a></th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>State</td>
                                                <th className="font-weight-bold">{data?.state}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>City</td>
                                                <th className="font-weight-bold">{data?.city}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Address</td>
                                                <th className="font-weight-bold" dangerouslySetInnerHTML={{__html: data?.address}}></th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Payment Method</td>
                                                <th className="font-weight-bold">{data?.payment_method}</th>
                                            </tr>
                                            <tr>
                                                <td style={{width: '150px'}}>Notes</td>
                                                <th className="font-weight-bold" dangerouslySetInnerHTML={{__html: data?.notes}}></th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Detail;