import { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import Filter from '../Filters/Filter';
import useFetch from '../../../useFetch';
import Paginate from '../../../Paginate';
import DeletePopup from '../DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Image, Table } from 'react-bootstrap';

const Index = () => {
    document.title = "Orders | CellyCash";
    
    const [searchParams, setSearchParams] = useSearchParams();

    var url = new URL(process.env.REACT_APP_BASE_API_URL+'/orders');
    for (const [key, value] of searchParams.entries())
    {
        url.searchParams.set(key, value);
    }

    const [apiUrl, setApiUrl] = useState(url);

    const filters = {
        action: apiUrl,
        method: 'GET',
        fields: [
            {
                label: 'Order Number',
                type: 'input',
                name: 'number',
            },
            {
                label: 'Status',
                type: 'select',
                name: 'status',
                values: [
                    { text: 'All', value: '' },
                    { text: 'Pending', value: 'Pending'},
                    { text: 'On Hold', value: 'On Hold'},
                    { text: 'In Progress', value: 'In Progress'},
                    { text: 'Completed', value: 'Completed'},
                    { text: 'Not Interested', value: 'Not Interested'},
                    { text: 'Rejected', value: 'Rejected'},
                    { text: 'Fake', value: 'Fake'},
                ]
            },
            {
                label: 'User Details',
                type: 'input',
                name: 'user',
            },
        ],
        sort: {
            label: 'Sort By',
            name: 'sort',
            values: [
                { text: 'Newly ordered', value: 'new' },
                { text: 'Oldest ordered', value: 'old' },
            ]
        }
    };

    const [items, setItems] = useState([]);
    const [meta, setMeta] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error: fetchError } = useFetch(apiUrl);

    useEffect(() => {
        setItems(response?.orders?.data ?? []);
        setMeta(response?.orders?.meta ?? []);
    }, [response])

    useEffect(() => {
        for (const [key, value] of searchParams.entries())
        {
            url.searchParams.set(key, value);
        }
        setApiUrl(url);
    }, [searchParams])

    return (
        <div className="container-fluid">
            {deleteItem ? <DeletePopup setItems={setItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <Filter setApiUrl={setApiUrl} setCurrentPage={setCurrentPage} filters={filters} />
            
            <Card className="shadow mb-4">
                <Card.Header className="py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Orders</h5>
                </Card.Header>
                <Card.Body>
                    { fetchError && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ fetchError }</strong></h3></div> }

                    { isLoading && <h5 className="text-center">Loading Data</h5> }
                    
                    { !fetchError && !isLoading ? (
                        items?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <>
                                <Table responsive bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{minWidth: '180px', width: '180px'}}>Order Date</th>
                                            <th style={{minWidth: '120px'}}>Order Number</th>
                                            <th style={{minWidth: '140px'}}>Status</th>
                                            <th style={{minWidth: '120px'}}>Amount</th>
                                            <th style={{minWidth: '140px', width: '300px'}}>User Details</th>
                                            <th style={{width: '1px' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items?.map((item) => (
                                            <tr key={ item.id }>
                                                <td>{ item.created_at }</td>
                                                <td>{ item.order_number }</td>
                                                <td><span className={`badge badge-table badge-${item.status_badge}`}>{ item.status }</span></td>
                                                <td>Rs. { item.total }</td>
                                                <td>
                                                    { item.name }
                                                    {item?.phone ? <p className="mb-0"><a href={`tel:${item?.phone}`}>{item?.phone}</a></p> : null}
                                                    <p className="mb-0">{ item.city }</p>
                                                </td>
                                                <td style={{padding: '0.3rem'}}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item as={Link} to={`detail/${item.id}`}>View</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'orders/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <Paginate
                                    url={apiUrl}
                                    setUrl={setApiUrl}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={meta?.last_page}
                                />

                                <i className="d-block text-center">{meta?.total ? `(${meta?.total} records found)` : null}</i>
                            </>
                        )
                    ) : null}
                </Card.Body>
            </Card>
        </div>
    );
}
 
export default Index;