import { useEffect, useState, useRef } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from '../../../useFetch';
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";

const Edit = () => {    
    document.title = "Edit Accessory | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    const fileRef = useRef();

    const maxFiles = 20;

    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState(0);
    const [cover, setCover] = useState('');

    const [previewDescription, setPreviewDescription] = useState(null);

    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/accessories/'+id);
    useEffect(() => {
        if (response?.data)
        {
            if (response.data.category_id) setCategory(response.data.category_id);
            if (response.data.title) setTitle(response.data.title);
            if (response.data.description) setDescription(response.data.description);
            if (response.data.price) setPrice(response.data.price);
        
            setUploadedFiles(response.data.images.map((image, index) => {
                return {full_path: image, original_name: response.data.images_raw[index], name: response.data.images_raw[index]};
            }));

            setPreviewDescription(null);
        }
    }, [response])

    const { response: categories } = useFetch(process.env.REACT_APP_BASE_API_URL+'/accessory-category-dropdown');
    
    const handleImageChange = (e) => {
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();

        var remainingFiles = maxFiles - uploadedFiles.length - uploadingFiles;
        remainingFiles = Math.min(e.target.files.length, remainingFiles);
        for(var i=0; i<remainingFiles; i++)
        {
            formData.append("images[]", e.target.files[i]);
        }
        setUploadingFiles(current => current + remainingFiles);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/upload-files', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while uploading images, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                data.images.map((image) => {
                    setUploadingFiles(current => current > 1 ? current - 1 : 0);
                    setUploadedFiles(values => ([...values, image]));
                });
            }
            else if (data?.status == 'validation_error')
            {
                setUploadingFiles(0);
                setError(data.messages[0]);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setUploadingFiles(0);
                setError(data.message);
            }
            else
            {
                setUploadingFiles(0);
                setError('There was some error while uploading images, please try again.');
            }

            if (fileRef?.current)
            {
                fileRef.current.value = '';
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setUploadingFiles(0);
            setError('There was some error while uploading images, please try again.');

            if (fileRef?.current)
            {
                fileRef.current.value = '';
            }
        })
    }

    const handleDeleteImage = (name) => {
        setUploadedFiles(uploadedFiles.filter((file) => {
            return file.name != name;
        }));
    }

    const handleCover = (name) => {
        setCover(name);
    }

    useEffect(() => {       
        setCover(value => {
            if (uploadedFiles.length == 0)
                return '';
            else if (uploadedFiles.filter((file) => file.name == cover).length == 0)
                return uploadedFiles[0].name;
            else
                return value;
        });
    }, [uploadedFiles])
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        var images = uploadedFiles.map((file) => file.name).join();
        
        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("accessory_category", category);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("images", images);
        formData.append("cover", cover);

        fetch(process.env.REACT_APP_BASE_API_URL+'/accessories/'+id, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while adding accessory, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding accessory, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handlePreviewDescription = (e) => {
        e.preventDefault();

        setPreviewDescription(null);

        let formData = new FormData();
        formData.append('description', description)

        fetch(process.env.REACT_APP_BASE_API_URL+'/preview-description', {
            method: 'POST',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error, please try again.');
            }

            return response.text();
        })
        .then(data => {
            if (data != '') 
            {
                setPreviewDescription(data);
            }
            else
            {
                setPreviewDescription('No description entered');
            }
        })
        .catch(error => {
            console.log(error)
        })
    }

    return ( 
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div ref={cardRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Accessory</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                            <Link to={`/accessories/detail/${id}`} className="mr-3 text-success">Details</Link>
                            <a href="#" onClick={() => navigate(-1)}>Back</a>
                        </h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Select 
                                    label="Category"
                                    value={category}
                                    setter={setCategory}
                                    options={categories?.categories ?? [{text: 'Loading...', value: ''}]}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Title"
                                    value={title}
                                    setter={setTitle}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <label className="mb-1">Price</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">Rs.</span>
                                    </div>
                                    <Form.Control 
                                        type="text" 
                                        value={price}
                                        onChange={ (e) => setPrice(e.target.value) }
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label>Upload Pictures</label>
                                <p className="mb-2">Upload upto {maxFiles} images</p>
                                <div className="row no-gutters mx-n2 row-cols-xl-7 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2 file-previews image-previews">
                                    <div className="col mb-2 px-2">
                                        <label className="custom-file-container my-0">
                                            <input 
                                                type="file" 
                                                ref={fileRef}
                                                onChange={handleImageChange}
                                                disabled={uploadedFiles.length+uploadingFiles>=maxFiles}
                                                className="custom-file-container-input" 
                                                accept="image/*" 
                                                multiple="multiple"/>
                                            
                                            <span className={`custom-file-container-control d-flex justify-content-center align-items-center flex-column ${uploadedFiles.length+uploadingFiles>=maxFiles ? 'text-danger' : ''}`}>
                                                <FontAwesomeIcon icon="plus" />
                                                <span className="text-center">Add Photos</span>
                                                <span className="small custom-file-container-input-count text-center">{uploadedFiles.length+uploadingFiles} files selected</span>
                                            </span>
                                        </label>
                                    </div>

                                    { uploadedFiles.map((uploadedFile) => (
                                        <div key={uploadedFile.name} className="col mb-2 px-2 custom-file-container-preview position-relative">
                                            <div className="custom-file-container-preview-item">
                                                <span onClick={() => handleDeleteImage(uploadedFile.name)} className="custom-file-container-preview-remove d-flex justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon="xmark" />
                                                </span>
                                                <img src={uploadedFile.full_path} className="img-fluid"/>
                                                </div>
                                                { cover == uploadedFile.name && <div className="custom-file-container-preview-make-cover active">Cover image</div> }
                                                { cover != uploadedFile.name && <div onClick={() => handleCover(uploadedFile.name)} className="custom-file-container-preview-make-cover">Make cover</div> }
                                        </div>
                                    ))}
                                    
                                    { [...Array(uploadingFiles)].map((uploadingFile, index) => (
                                        <div key={index} className="col mb-2 px-2 custom-file-container-preview custom-file-container-preview-dummy position-relative">
                                            <div className="custom-file-container-preview-item custom-file-container-preview-nonimage" style={{background: '#0E9877'}}>
                                                <p>Uploading</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
					        <div className="col-md-12 mb-3">
                                <div className="d-flex justify-content-between">
                                    <Form.Label className="mb-1">Description</Form.Label>
							        <a href="#" onClick={handlePreviewDescription} >Preview Description</a>
						        </div>
                                <Form.Control
                                    as="textarea"
                                    value={ description }
                                    onChange={ (e) => setDescription(e.target.value) }
                                    required={true}
                                    rows={5}
                                ></Form.Control>
                                    
                                {previewDescription ? (
                                    <div style={{
                                        'padding': '19px',
                                        'marginTop': '10px',
                                        'marginBottom': '20px',
                                        'backgroundColor': '#f5f5f5',
                                        'border': '1px solid #e3e3e3',
                                        'borderRadius': '4px',
                                        'boxShadow': 'inset 0 1px 1px rgb(0 0 0 / 5%)',
                                    }} 
                                    dangerouslySetInnerHTML={{__html: previewDescription}}></div>
                                ) : null}

                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Show/hide description guide</Accordion.Header>
                                        <Accordion.Body>
                                            <p className="mt-1 mb-2">Start description with <b className="text-primary" style={{'fontSize': '40px', 'lineHeight': '0px', 'position': 'relative', 'top': '5px'}}>~</b> if breaking description in multiple formats. <b>Add line break after this symbol.</b></p>
                                            <p className="pt-1 mb-2">For empty line, type <b className="text-primary" style={{'fontSize': '30px', 'top': '2px', 'lineHeight': '0px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>+</b> and don't type anything after this symbol.</p>
                                            <p className="pt-1 mb-0">To format line, start line with</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '30px', 'top': '4px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>=</b> for plain text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '35px', 'top': '12px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>*</b> for <b>bold</b> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '20px', 'top': '0px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>/</b> for <i>italic</i> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '40px', 'top': '-5px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>_</b> for <u>underline</u> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '22px', 'top': '2px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>%</b> for <b><i>bold and italic</i></b> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '22px', 'top': '0px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>$</b> for <b><u>bold and underline</u></b> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '22px', 'top': '0px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>@</b> for <i><u>italic and underline</u></i> text.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '25px', 'top': '2px', 'lineHeight': '0px', 'width': '30px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>#</b> for <b><i><u>bold, italic and underline</u></i></b> text.</p>
                                            <p className="pt-1 mt-3 mb-0">To show icons before text, add these number after symbol</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>0</b> for no icon.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>1</b> for tick.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>2</b> for discount tag.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>3</b> for calendar.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>4</b> for shipping.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>5</b> for verified refurbished.</p>
                                            <p className="pt-1 mb-0"><b className="text-primary" style={{'fontSize': '24px', 'top': '2px', 'lineHeight': '0px', 'width': '20px', 'textAlign': 'center', 'display': 'inline-block', 'position': 'relative'}}>6</b> for warranty shield.</p>
      
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default Edit;