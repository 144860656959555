import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Button, Table } from 'react-bootstrap';

const Detail = () => {
    document.title = "Instant Cash Request Details | CellyCash";

    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [successStatus, setSuccessStatus] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash-requests/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
        }
    }, [response])

    const handleStatus = (e, status) => {
        e.preventDefault();

        setIsLoadingStatus(true);
        setSuccessStatus(null);
        setErrorStatus(null);

        fetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash-requests/'+id+'/change-status/'+status, {
            method: 'POST',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while changing status, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingStatus(false);

            if (data?.status == 'success')
            {
                setSuccessStatus(data.message);
                setData(data.data);
            }
            else
            {
                setErrorStatus(data.message);
            }
        })
        .catch(error => {
            setIsLoadingStatus(false);
            setErrorStatus(error.message);
        })
    }
    
    return (
        <div className="container-fluid"> 
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Instant Cash Request Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <>
                                <div className="mb-2">
                                    <label className="mb-1">Change Status</label>
                                    <p className="font-weight-bold mb-0">
                                        <Button variant="warning" onClick={(e) => handleStatus(e, 'pending')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Pending</Button>
                                        <Button variant="primary" onClick={(e) => handleStatus(e, 'On Hold')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">On Hold</Button>
                                        <Button variant="info" onClick={(e) => handleStatus(e, 'In Progress')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">In Progress</Button>
                                        <Button variant="success" onClick={(e) => handleStatus(e, 'Completed')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Completed</Button>
                                        <Button variant="danger" onClick={(e) => handleStatus(e, 'Not Interested')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Not Interested</Button>
                                        <Button variant="danger" onClick={(e) => handleStatus(e, 'Rejected')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Rejected</Button>
                                        <Button variant="danger" onClick={(e) => handleStatus(e, 'Fake')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Fake</Button>
                                    </p>
                                    { successStatus && <div className="alert-msg-success flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ successStatus }</strong></h3></div> }
                                    { errorStatus && <div className="alert-msg-danger flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ errorStatus }</strong></h3></div> }
                                </div>

                                <Table responsive hover style={{border: '1px solid #dee2e6'}}>
                                    <tbody>
                                        <tr>
                                            <td style={{width: '210px'}}>Status</td>
                                            <th className="font-weight-bold">
                                                <span className={`badge badge-table badge-${data?.status_badge} mr-3`}>{ data?.status }</span>
                                                {data?.status_changed_at ? (<div className="badge badge-success d-inline-block mt-1" style={{whiteSpace: 'initial', lineHeight: '1.2'}}>Changed: {data?.status_changed_at}</div>) : null}
                                            </th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Request Date</td>
                                            <th className="font-weight-bold">{data?.created_at}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Request Number</td>
                                            <th className="font-weight-bold">{data?.order_number}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Brand</td>
                                            <th className="font-weight-bold">{data?.brand}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Model</td>
                                            <th className="font-weight-bold">{data?.mobile}</th>
                                        </tr>
                                        {data?.variant ? (
                                            <tr>
                                                <td style={{width: '210px'}}>Variant</td>
                                                <th className="font-weight-bold">{data?.variant}</th>
                                            </tr>
                                        ) : null}
                                        <tr>
                                            <td style={{width: '210px'}}>Name</td>
                                            <th className="font-weight-bold">{data?.name}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Email</td>
                                            <th className="font-weight-bold"><a href={`mailto:${data?.email}`}>{data?.email}</a></th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Phone</td>
                                            <th className="font-weight-bold"><a href={`tel:+1${data?.mobile_number}`}>+1{data?.mobile_number}</a></th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>State</td>
                                            <th className="font-weight-bold">{data?.state}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>City</td>
                                            <th className="font-weight-bold">{data?.city}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Address</td>
                                            <th className="font-weight-bold" dangerouslySetInnerHTML={{__html: data?.address}}></th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Provider</td>
                                            <th className="font-weight-bold">{data?.provider}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Phone broken</td>
                                            <th className="font-weight-bold">{data?.is_broken} {data?.broken_sides ? '('+data?.broken_sides+')' : ''}</th>
                                        </tr>
                                        {data?.is_broken == 'No' ? (
                                            <>
                                                <tr>
                                                    <td style={{width: '210px'}}>Phone condition</td>
                                                    <th className="font-weight-bold">{data?.q1}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '210px'}}>Screen scratches condition</td>
                                                    <th className="font-weight-bold">{data?.q2}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '210px'}}>Battery health</td>
                                                    <th className="font-weight-bold">{data?.q4}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '210px'}}>Body damage</td>
                                                    <th className="font-weight-bold">{data?.q5}</th>
                                                </tr>
                                                <tr>
                                                    <td style={{width: '210px'}}>Phone back condition</td>
                                                    <th className="font-weight-bold">{data?.q6}</th>
                                                </tr>
                                            </>
                                        ) : null}
                                        <tr>
                                            <td style={{width: '210px'}}>Original Charger</td>
                                            <th className="font-weight-bold">{data?.charger}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>Original IMEI Box</td>
                                            <th className="font-weight-bold">{data?.box}</th>
                                        </tr>
                                        <tr>
                                            <td style={{width: '210px'}}>{data?.issues ? 'Issues with' : 'Issues'}</td>
                                            <th className="font-weight-bold">{data?.issues ? data?.issues : 'No issue'}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Detail;