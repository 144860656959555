import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useFetch from '../../../../useFetch';
import Input from "./../../Forms/Input";
import Select from "./../../Forms/Select";
import Textarea from "./../../Forms/Textarea";

const Edit = () => {
    document.title = "Edit Instant Cash Mobile Variant | CellyCash";
    
    const { id, variantId } = useParams();
    let navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();

    const [order, setOrder] = useState('');
    const [name, setName] = useState('');
    const [price_at_t, setPrice_at_t] = useState('');
    const [price_factory_unlocked, setPrice_factory_unlocked] = useState('');
    const [price_sprint, setPrice_sprint] = useState('');
    const [price_tmobile, setPrice_tmobile] = useState('');
    const [price_verizon, setPrice_verizon] = useState('');

    const { response } = useFetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash-mobiles/'+id+'/variants/'+variantId);
    useEffect(() => {
        if (response?.data)
        {
            if (response.data.order) setOrder(response.data.order);
            if (response.data.name) setName(response.data.name);
            if (response.data.price_at_t) setPrice_at_t(response.data.price_at_t)
            if (response.data.price_factory_unlocked) setPrice_factory_unlocked(response.data.price_factory_unlocked)
            if (response.data.price_sprint) setPrice_sprint(response.data.price_sprint)
            if (response.data.price_tmobile) setPrice_tmobile(response.data.price_tmobile)
            if (response.data.price_verizon) setPrice_verizon(response.data.price_verizon)
        }
    }, [response])
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("_method", "PUT");
        formData.append("order", order);
        formData.append("name", name);
        formData.append('price_at_t', price_at_t)
        formData.append('price_factory_unlocked', price_factory_unlocked)
        formData.append('price_sprint', price_sprint)
        formData.append('price_tmobile', price_tmobile)
        formData.append('price_verizon', price_verizon)

        fetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash-mobiles/'+id+'/variants/'+variantId, {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while editing variant, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while editing variant, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">            
            <form onSubmit={handleSubmit}>
                <div ref={cardRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Variant</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Order"
                                    value={order}
                                    setter={setOrder}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Name"
                                    value={name}
                                    setter={setName}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Price AT&T"
                                    value={price_at_t}
                                    setter={setPrice_at_t}
                                    required={true}
                                />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Price Factory Unlocked"
                                    value={price_factory_unlocked}
                                    setter={setPrice_factory_unlocked}
                                    required={true}
                                />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Price Sprint"
                                    value={price_sprint}
                                    setter={setPrice_sprint}
                                    required={true}
                                />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Price T-Mobile"
                                    value={price_tmobile}
                                    setter={setPrice_tmobile}
                                    required={true}
                                />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Price Verizon"
                                    value={price_verizon}
                                    setter={setPrice_verizon}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default Edit;