import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Dropdown, Table } from 'react-bootstrap';
import DeletePopup from '../DeletePopup';

const Detail = () => {
    document.title = "Repair Mobile Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/repair-mobiles/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
        }
    }, [response])
    
    return (
        <div className="container-fluid"> 
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Repair Mobile Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                        <Link to={`/repair-mobiles/edit/${id}`} className="mr-3 text-success">Edit</Link>
                        <a href="#" onClick={() => navigate(-1)}>Back</a>
                    </h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Brand</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.name}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Order</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.order}-{data?.order}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Name</label>
                                    <p className="font-weight-bold mb-0">{data?.name}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Permalink</label>
                                    <p className="font-weight-bold mb-0">{data?.permalink}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-1">Image</label>
                                    <p className="font-weight-bold mb-0"><img src={data?.image} style={{maxHeight: '70px', maxWidth: '70px'}} /></p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-0">Description</label>
                                    <p className="font-weight-bold mb-0" dangerouslySetInnerHTML={{__html: data?.description_output}}></p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Detail;