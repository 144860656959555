import { Form } from "react-bootstrap";

const Input = ({ label, name, value, setter, required, type = 'text' }) => {
    return ( 
        <>
            <Form.Label className="mb-1">{ label }</Form.Label>
            <Form.Control 
                type={type}
                name={ name } 
                value={ value }
                onChange={ (e) => setter(e.target.value) }
                required={required ? true : false}
                />
        </>
     );
}
 
export default Input;