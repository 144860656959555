import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, Route, Routes } from "react-router-dom";
import '../assets/css/sb-admin-2.min.css';
import '../assets/css/styles.css';

import Login from "./Login";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";

import BrandIndex from './Brands/Index';
import BrandCreate from './Brands/Create';
import BrandEdit from './Brands/Edit';
import BrandDetail from './Brands/Detail';

import AdIndex from './Ads/Index';
import AdDetail from './Ads/Detail';

import CertifiedPhoneIndex from "./CertifiedPhones/Index";
import CertifiedPhoneCreate from "./CertifiedPhones/Create";
import CertifiedPhoneEdit from "./CertifiedPhones/Edit";
import CertifiedPhoneDetail from "./CertifiedPhones/Detail";
import CertifiedPhoneItemCreate from "./CertifiedPhones/Items/Create";
import CertifiedPhoneItemEdit from "./CertifiedPhones/Items/Edit";

import AccessoryIndex from "./Accessories/Index";
import AccessoryCreate from "./Accessories/Create";
import AccessoryEdit from "./Accessories/Edit";
import AccessoryDetail from "./Accessories/Detail";

import AccessoryCategoryIndex from "./AccessoryCategories/Index";
import AccessoryCategoryCreate from "./AccessoryCategories/Create";
import AccessoryCategoryEdit from "./AccessoryCategories/Edit";
import AccessoryCategoryDetail from "./AccessoryCategories/Detail";

import OrderIndex from './Orders/Index';
import OrderDetail from './Orders/Detail';

import InstantCashRequestIndex from './InstantCashRequests/Index';
import InstantCashRequestDetail from './InstantCashRequests/Detail';

import RepairRequestIndex from './RepairRequests/Index';
import RepairRequestDetail from './RepairRequests/Detail';

import PushNotificationsCreate from './PushNotifications';

import SpecsMobileIndex from "./SpecsMobiles/Index";
import SpecsMobileCreate from "./SpecsMobiles/Create";
import SpecsMobileEdit from "./SpecsMobiles/Edit";
import SpecsMobileDetail from "./SpecsMobiles/Detail";
import SpecsMobileItemCreate from "./SpecsMobiles/Items/Create";
import SpecsMobileItemEdit from "./SpecsMobiles/Items/Edit";

import InstantCashMobileIndex from "./InstantCashMobiles/Index";
import InstantCashMobileCreate from "./InstantCashMobiles/Create";
import InstantCashMobileEdit from "./InstantCashMobiles/Edit";
import InstantCashMobileDetail from "./InstantCashMobiles/Detail";
import InstantCashMobileVariantCreate from "./InstantCashMobiles/Variants/Create";
import InstantCashMobileVariantEdit from "./InstantCashMobiles/Variants/Edit";

import RepairMobileIndex from "./RepairMobiles/Index";
import RepairMobileCreate from "./RepairMobiles/Create";
import RepairMobileEdit from "./RepairMobiles/Edit";
import RepairMobileDetail from "./RepairMobiles/Detail";

import ContactMessageIndex from './ContactMessages/Index';
import ContactMessageDetail from './ContactMessages/Detail';

import BannerIndex from "./Banners/Index";
import BannerCreate from "./Banners/Create";
import BannerEdit from "./Banners/Edit";

import ImeiIndex from "./Imei/Index";
import ImeiCreate from "./Imei/Create";
import ImeiEdit from "./Imei/Edit";

import CertifiedOptionIndex from "./CertifiedOptions/Index";
import CertifiedOptionCreate from "./CertifiedOptions/Create";
import CertifiedOptionEdit from "./CertifiedOptions/Edit";
import CertifiedOptionDetail from "./CertifiedOptions/Detail";
import CertifiedOptionItemCreate from "./CertifiedOptions/Items/Create";
import CertifiedOptionItemEdit from "./CertifiedOptions/Items/Edit";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import useToken from "../../useToken";

const App = () => {
    const { token, setToken, removeToken } = useToken();
    const [sidebarToggled, setSidebarToggled] = useState(true);

    if (!token)
    {
        document.title = "Login | CellyCash";
        return (
            <Login setToken={setToken} />
        )
    }

    return ( 
        <div className={`admin-app ${sidebarToggled ? 'app-sidebar-toggled' : ''}`} id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar 
                        sidebarToggled={sidebarToggled}
                        setSidebarToggled={setSidebarToggled}
                        removeToken={removeToken} />

                    <Routes basename={'/directory-name'}>
                        <Route path="/" element={<Dashboard />} />
                        
                        <Route path="buy-phones" element={<AdIndex />} />
                        <Route path="buy-phones/detail/:id" element={<AdDetail />} />
                        
                        <Route path="orders" element={<OrderIndex />} />
                        <Route path="orders/detail/:id" element={<OrderDetail />} />
                        
                        <Route path="instant-cash-requests" element={<InstantCashRequestIndex />} />
                        <Route path="instant-cash-requests/detail/:id" element={<InstantCashRequestDetail />} />
                        
                        <Route path="repair-requests" element={<RepairRequestIndex />} />
                        <Route path="repair-requests/detail/:id" element={<RepairRequestDetail />} />
                        
                        <Route path="contact-messages" element={<ContactMessageIndex />} />
                        <Route path="contact-messages/detail/:id" element={<ContactMessageDetail />} />

                        <Route path="push-notifications" element={<PushNotificationsCreate />} />

                        <Route path="certified-phones" element={<CertifiedPhoneIndex />} />
                        <Route path="certified-phones/create" element={<CertifiedPhoneCreate />} />
                        <Route path="certified-phones/edit/:id" element={<CertifiedPhoneEdit />} />
                        <Route path="certified-phones/detail/:id" element={<CertifiedPhoneDetail />} />
                        <Route path="certified-phones/detail/:id/items/create" element={<CertifiedPhoneItemCreate />} />
                        <Route path="certified-phones/detail/:id/items/edit/:itemId" element={<CertifiedPhoneItemEdit />} />

                        <Route path="accessories" element={<AccessoryIndex />} />
                        <Route path="accessories/create" element={<AccessoryCreate />} />
                        <Route path="accessories/edit/:id" element={<AccessoryEdit />} />
                        <Route path="accessories/detail/:id" element={<AccessoryDetail />} />
                        
                        <Route path="specs-mobiles" element={<SpecsMobileIndex />} />
                        <Route path="specs-mobiles/create" element={<SpecsMobileCreate />} />
                        <Route path="specs-mobiles/edit/:id" element={<SpecsMobileEdit />} />
                        <Route path="specs-mobiles/detail/:id" element={<SpecsMobileDetail />} />
                        <Route path="specs-mobiles/detail/:id/items/create" element={<SpecsMobileItemCreate />} />
                        <Route path="specs-mobiles/detail/:id/items/edit/:itemId" element={<SpecsMobileItemEdit />} />
                        
                        <Route path="instant-cash-mobiles" element={<InstantCashMobileIndex />} />
                        <Route path="instant-cash-mobiles/create" element={<InstantCashMobileCreate />} />
                        <Route path="instant-cash-mobiles/edit/:id" element={<InstantCashMobileEdit />} />
                        <Route path="instant-cash-mobiles/detail/:id" element={<InstantCashMobileDetail />} />
                        <Route path="instant-cash-mobiles/detail/:id/variants/create" element={<InstantCashMobileVariantCreate />} />
                        <Route path="instant-cash-mobiles/detail/:id/variants/edit/:variantId" element={<InstantCashMobileVariantEdit />} />
                        
                        <Route path="repair-mobiles" element={<RepairMobileIndex />} />
                        <Route path="repair-mobiles/create" element={<RepairMobileCreate />} />
                        <Route path="repair-mobiles/edit/:id" element={<RepairMobileEdit />} />
                        <Route path="repair-mobiles/detail/:id" element={<RepairMobileDetail />} />

                        <Route path="brands" element={<BrandIndex />} />
                        <Route path="brands/create" element={<BrandCreate />} />
                        <Route path="brands/edit/:id" element={<BrandEdit />} />
                        <Route path="brands/detail/:id" element={<BrandDetail />} />
                        
                        <Route path="accessory-categories" element={<AccessoryCategoryIndex />} />
                        <Route path="accessory-categories/create" element={<AccessoryCategoryCreate />} />
                        <Route path="accessory-categories/edit/:id" element={<AccessoryCategoryEdit />} />
                        <Route path="accessory-categories/detail/:id" element={<AccessoryCategoryDetail />} />
                        
                        <Route path="banners" element={<BannerIndex />} />
                        <Route path="banners/create" element={<BannerCreate />} />
                        <Route path="banners/edit/:id" element={<BannerEdit />} />
                        
                        <Route path="imei" element={<ImeiIndex />} />
                        <Route path="imei/create" element={<ImeiCreate />} />
                        <Route path="imei/edit/:id" element={<ImeiEdit />} />
                        
                        <Route path="certified-options" element={<CertifiedOptionIndex />} />
                        <Route path="certified-options/create" element={<CertifiedOptionCreate />} />
                        <Route path="certified-options/edit/:id" element={<CertifiedOptionEdit />} />
                        <Route path="certified-options/detail/:id" element={<CertifiedOptionDetail />} />
                        <Route path="certified-options/detail/:id/items/create" element={<CertifiedOptionItemCreate />} />
                        <Route path="certified-options/detail/:id/items/edit/:itemId" element={<CertifiedOptionItemEdit />} />
                        
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
 
export default App;