import { useState } from "react";
import { Form } from "react-bootstrap";

const Login = ({setToken}) => {
    document.body.classList.add('bg-gradient-primary');

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("type", "email");
        formData.append("email", email);
        formData.append("password", password);

        fetch(process.env.REACT_APP_BASE_API_URL+'/login', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);
                setToken(data.access_token);
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while logging in, please try again.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-6 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-3">
                            <div className="pt-3 px-3 pb-1">
                                <div className="text-center">
                                    <img id="profile-img" className="profile-img-card" src="https://cellycash.com/images/logo.svg" style={{width: '300px'}} />
                                    <h1 className="h4 text-gray-900 my-3 text-center">Admin Login</h1>
                                </div>
                                { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                                { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                                { validationErrors.length > 0 &&
                                    <div className="alert-msg-danger">
                                        <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                        <ul className="mb-0" style={{fontSize: '15px'}}>
                                            { validationErrors.map((validationError, index) => (
                                                <li key={index}>{ validationError }</li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                        
                                <form className="user" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <Form.Label className="mb-1">Email</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="email"
                                            value={ email }
                                            onChange={ (e) => setEmail(e.target.value) }
                                            placeholder="Enter your email"
                                            required={ true }
                                            />
                                    </div>

                                    <div className="form-group">
                                        <Form.Label className="mb-1">Password</Form.Label>
                                        <Form.Control 
                                            type="password" 
                                            name="password"
                                            value={ password }
                                            onChange={ (e) => setPassword(e.target.value) }
                                            placeholder="Enter your password"
                                            required={ true }
                                            />
                                    </div>

                                    <button 
                                        type="submit" 
                                        className="btn btn-secondary btn-user btn-block"
                                        disabled={isLoading}
                                        >{ isLoading ? 'Loading...' : 'Login' }</button>
                                </form>
                                <div className="text-center mt-3">
                                    <a className="small" href="https://cellycash.com/">Back to Main Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Login;