import { useEffect } from "react";
import { useState } from "react";

export default function useToken() {
    const getToken = () => {
        return localStorage.getItem("token");
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        localStorage.setItem("token", userToken);
        setToken(userToken);
    };

    const removeToken = (userToken) => {
        localStorage.removeItem("token")
        setToken(null);
    };

    return {
        setToken: saveToken,
        removeToken,
        token,
    };
}
