import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";

const Create = () => {
    document.title = "Add Banner | CellyCash";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    const fileRefLarge = useRef();
    const fileRefMedium = useRef();
    const fileRefSmall = useRef();

    const [order, setOrder] = useState('');
    const [link, setLink] = useState('');
    const [useUrlLarge, setUseUrlLarge] = useState(false);
    const [imageUrlLarge, setImageUrlLarge] = useState('');
    const [imageLarge, setImageLarge] = useState(null);
    const [useUrlMedium, setUseUrlMedium] = useState(false);
    const [imageUrlMedium, setImageUrlMedium] = useState('');
    const [imageMedium, setImageMedium] = useState(null);
    const [useUrlSmall, setUseUrlSmall] = useState(false);
    const [imageUrlSmall, setImageUrlSmall] = useState('');
    const [imageSmall, setImageSmall] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("order", order);
        formData.append("link", link);
        if (useUrlLarge)
        {
            formData.append("use_image_large_url", "on");
            formData.append("image_large_url", imageUrlLarge);
        }
        if (!useUrlLarge && imageLarge)
        {
            formData.append("image_large", imageLarge);
        }
        
        if (useUrlMedium)
        {
            formData.append("use_image_medium_url", "on");
            formData.append("image_medium_url", imageUrlMedium);
        }
        if (!useUrlMedium && imageMedium)
        {
            formData.append("image_medium", imageMedium);
        }
        
        if (useUrlSmall)
        {
            formData.append("use_image_small_url", "on");
            formData.append("image_small_url", imageUrlSmall);
        }
        if (!useUrlSmall && imageSmall)
        {
            formData.append("image_small", imageSmall);
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/info-banner', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while adding banner, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setOrder('');
                setLink('');
                setUseUrlLarge(false);
                setImageUrlLarge('');
                setImageLarge(null);
                if (fileRefLarge?.current)
                {
                    fileRefLarge.current.value = '';
                }
                setUseUrlMedium(false);
                setImageUrlMedium('');
                setImageMedium(null);
                if (fileRefMedium?.current)
                {
                    fileRefMedium.current.value = '';
                }
                setUseUrlSmall(false);
                setImageUrlSmall('');
                setImageSmall(null);
                if (fileRefSmall?.current)
                {
                    fileRefSmall.current.value = '';
                }
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding banner, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div ref={cardRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Banner</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Order"
                                    value={order}
                                    setter={setOrder}
                                    required={true}
                                    />
                            </div>
                            <div className="col-md-8 mb-3">
                                <Input 
                                    label="Link"
                                    value={link}
                                    setter={setLink}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <div>
                                    <Form.Label className="mb-1">Large Image (&gt;= 992 pixels)</Form.Label>
                                    <div className="float-right">
                                        <input 
                                            type="checkbox" 
                                            checked={useUrlLarge}
                                            onChange={() => setUseUrlLarge(!useUrlLarge)}
                                            id="input-use_image_url_Large" /> 
                                        <Form.Label className="mb-1 ml-2" htmlFor="input-use_image_url_Large">(Use URL)</Form.Label>
                                    </div>
                                </div>
                                {useUrlLarge ? (
                                    <div id="div-image_url">
                                        <Form.Control 
                                            type="text" 
                                            value={imageUrlLarge}
                                            onChange={ (e) => setImageUrlLarge(e.target.value) }
                                            required={true}
                                        />
                                        {imageUrlLarge && <img src={imageUrlLarge} className="img-fluid mt-2" style={{backgroundColor: '#f3f3f3', padding: '5px', maxHeight: '100px',}}/> }
                                    </div>
                                ) : (
                                    <div id="div-image_file">
                                        <input 
                                            type="file" 
                                            ref={fileRefLarge}
                                            onChange={(e) => setImageLarge(e.target.files[0])}
                                            name="image" 
                                            className="form-control" 
                                            accept="image/*" 
                                            required />
                                    </div>
                                )}
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <div>
                                    <Form.Label className="mb-1">Medium Image (576 to 991 pixels)</Form.Label>
                                    <div className="float-right">
                                        <input 
                                            type="checkbox" 
                                            checked={useUrlMedium}
                                            onChange={() => setUseUrlMedium(!useUrlMedium)}
                                            id="input-use_image_url_Medium" /> 
                                        <Form.Label className="mb-1 ml-2" htmlFor="input-use_image_url_Medium">(Use URL)</Form.Label>
                                    </div>
                                </div>
                                {useUrlMedium ? (
                                    <div id="div-image_url">
                                        <Form.Control 
                                            type="text" 
                                            value={imageUrlMedium}
                                            onChange={ (e) => setImageUrlMedium(e.target.value) }
                                            required={true}
                                        />
                                        {imageUrlMedium && <img src={imageUrlMedium} className="img-fluid mt-2" style={{backgroundColor: '#f3f3f3', padding: '5px', maxHeight: '100px',}}/> }
                                    </div>
                                ) : (
                                    <div id="div-image_file">
                                        <input 
                                            type="file" 
                                            ref={fileRefMedium}
                                            onChange={(e) => setImageMedium(e.target.files[0])}
                                            name="image" 
                                            className="form-control" 
                                            accept="image/*" 
                                            required />
                                    </div>
                                )}
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <div>
                                    <Form.Label className="mb-1">Small Image (&le;= 575 pixels)</Form.Label>
                                    <div className="float-right">
                                        <input 
                                            type="checkbox" 
                                            checked={useUrlSmall}
                                            onChange={() => setUseUrlSmall(!useUrlSmall)}
                                            id="input-use_image_url_Small" /> 
                                        <Form.Label className="mb-1 ml-2" htmlFor="input-use_image_url_Small">(Use URL)</Form.Label>
                                    </div>
                                </div>
                                {useUrlSmall ? (
                                    <div id="div-image_url">
                                        <Form.Control 
                                            type="text" 
                                            value={imageUrlSmall}
                                            onChange={ (e) => setImageUrlSmall(e.target.value) }
                                            required={true}
                                        />
                                        {imageUrlSmall && <img src={imageUrlSmall} className="img-fluid mt-2" style={{backgroundColor: '#f3f3f3', padding: '5px', maxHeight: '100px',}}/> }
                                    </div>
                                ) : (
                                    <div id="div-image_file">
                                        <input 
                                            type="file" 
                                            ref={fileRefSmall}
                                            onChange={(e) => setImageSmall(e.target.files[0])}
                                            name="image" 
                                            className="form-control" 
                                            accept="image/*" 
                                            required />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default Create;