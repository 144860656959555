import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Input from "./Forms/Input";

const PushNotifications = () => {
    document.title = "Push Notifications | CellyCash";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    const fileRef = useRef();

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [image, setImage] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("title", title);
        formData.append("body", body);
        if (image)
        {
            formData.append("image", image);
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/push-notifications', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while sending push notifications, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setTitle('');
                setBody('');
                setImage(null);
                if (fileRef?.current)
                {
                    fileRef.current.value = '';
                }
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while sending push notifications, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div ref={cardRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Send Push Notifications</h5>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <Input
                                    label="Title"
                                    value={title}
                                    setter={setTitle}
                                    required={true}
                                    />
                            </div>
                            <div className="col-md-12 mb-3">
                                <Input 
                                    label="Body"
                                    value={body}
                                    setter={setBody}
                                    required={true}
                                    />
                            </div>
                            <div className="col-md-12 mb-3">
                                <Form.Label className="mb-1">Image</Form.Label>
                                <input 
                                    type="file" 
                                    ref={fileRef}
                                    onChange={(e) => setImage(e.target.files[0])}
                                    name="image" 
                                    className="form-control" 
                                    accept="image/*"/>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default PushNotifications;