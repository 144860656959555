import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Dropdown, Table } from 'react-bootstrap';
import DeletePopup from '../DeletePopup';

const Detail = () => {
    document.title = "Certified Phone Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [data, setData] = useState(null);
    const [prices, setPrices] = useState([]);
    
    const [deleteItem, setDeleteItem] = useState(null);

    const [isLoadingSold, setIsLoadingSold] = useState(false);
    const [successSold, setSuccessSold] = useState(null);
    const [errorSold, setErrorSold] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/certified-phones/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
            setPrices(response?.data?.prices);
        }
    }, [response])

    const handleSoldStatus = (e) => {
        e.preventDefault();

        setIsLoadingSold(true);
        setSuccessSold(null);
        setErrorSold(null);

        fetch(process.env.REACT_APP_BASE_API_URL+'/certified-phones/'+id+'/toggle-sold', {
            method: 'POST',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while changing sold status, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingSold(false);

            if (data?.status == 'success')
            {
                setSuccessSold(data.message);
                setData(data.data);
            }
            else
            {
                setErrorSold(data.message);
            }
        })
        .catch(error => {
            setIsLoadingSold(false);
            setErrorSold(error.message);
        })
    }
    
    return (
        <div className="container-fluid"> 
            {deleteItem ? <DeletePopup setItems={setPrices} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Certified Phone Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                        <Link to={`/certified-phones/edit/${id}`} className="mr-3 text-success">Edit</Link>
                        <a href="#" onClick={() => navigate(-1)}>Back</a>
                    </h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="form-row">
                                <div className="col-md-12 mb-3 d-flex align-items-center">
                                    { successSold && <div className="alert-msg-success flex-fill my-0 ml-3"><h3 className="mb-0"><strong>{ successSold }</strong></h3></div> }
                                    { errorSold && <div className="alert-msg-danger flex-fill my-0 ml-3"><h3 className="mb-0"><strong>{ errorSold }</strong></h3></div> }
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Posted Date</label>
                                    <p className="font-weight-bold mb-0">{data?.created_at}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Title</label>
                                    <p className="font-weight-bold mb-0">{data?.title}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Condition</label>
                                    <p className="font-weight-bold mb-0">{data?.condition}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Brand</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.name}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Options</label>
                                    <p className="font-weight-bold mb-0">{data?.options?.map(value => value.name)?.join(', ')}</p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-0">Description</label>
                                    <p className="font-weight-bold mb-0" dangerouslySetInnerHTML={{__html: data?.description_output}}></p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-1">Images</label>
                                    <div className="row no-gutters mx-n2 row-cols-xl-7 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
                                        {data?.images?.map(image => (
                                            <div key={image} className="col mb-2 px-2 custom-file-container-preview position-relative">
                                                <div className="custom-file-container-preview-item">
                                                    <img src={image} className="img-fluid"/>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            {!isLoading && !error ? (
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Variants</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                            <Link to={`/certified-phones/detail/${id}/items/create`}>Add New</Link>
                        </h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        {prices?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{minWidth: '120px'}}>Price</th>
                                        <th style={{minWidth: '100px'}}>Options</th>
                                        <th style={{width: '1px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { prices?.map((item) => (
                                        <tr key={ item.id }>
                                            <td>Rs. { item.price }</td>
                                            <td>{ item.items?.filter(value => data?.options?.map(value => value.id)?.includes(value.option_id))?.map(value => value.option+': '+value.option_item)?.join(', ') }</td>
                                            <td style={{padding: '0.3rem'}}>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as={Link} to={`/certified-phones/detail/${id}/items/edit/${item.id}`}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'certified-phones/'+id+'/items/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
 
export default Detail;