import { useEffect, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Input from "./../Forms/Input";
import Select from "./../Forms/Select";
import Textarea from "./../Forms/Textarea";

const Create = () => {
    document.title = "Add Accessory Category | CellyCash";
    
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const cardRef = useRef();
    const fileRef = useRef();

    const [order, setOrder] = useState('');
    const [name, setName] = useState('');
    const [permalink, setPermalink] = useState('');
    const [showInListing, setShowInListing] = useState('yes');
    const [description, setDescription] = useState('');
    const [uploadImage, setUploadImage] = useState(false);
    const [useUrl, setUseUrl] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [image, setImage] = useState(null);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setIsLoading(true);
        setValidationErrors(false);
        setError(false);
        setSuccess(false);

        let formData = new FormData();
        formData.append("order", order);
        formData.append("name", name);
        formData.append("permalink", permalink);
        formData.append("description", description);
        formData.append("show_in_listing", showInListing);
        if (uploadImage)
        {
            formData.append("upload_image", "on");

            if (useUrl)
            {
                formData.append("use_image_url", "on");
                formData.append("image_url", imageUrl);
            }
            if (!useUrl && image)
            {
                formData.append("image", image);
            }
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/accessory-categories', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while adding category, please try again.');
            }

            return response.json();
        })
        .then(data => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);

            if (data?.status == 'success')
            {
                setSuccess(data.message);

                setOrder('');
                setName('');
                setPermalink('');
                setShowInListing('yes');
                setDescription('');
                setUploadImage(false);
                setUseUrl(false);
                setImageUrl('');
                setImage(null);
                if (fileRef?.current)
                {
                    fileRef.current.value = '';
                }
            }
            else if (data?.status == 'validation_error')
            {
                setValidationErrors(data.messages);
            }
            else if (data?.status == 'error' && data?.message)
            {
                setError(data.message);
            }
            else
            {
                setError('There was some error while adding category, please try again.');
            }
        })
        .catch(error => {
            cardRef.current.scrollIntoView();
            setIsLoading(false);
            setError(error.message);
        })
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div ref={cardRef} className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Accessory Category</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        { success && <div className="alert-msg-success"><h3 className="mb-0"><strong>{ success }</strong></h3></div> }
                        
                        { error && <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> }

                        { validationErrors.length > 0 &&
                            <div className="alert-msg-danger">
                                <h3 className="mb-1"><strong>There was some error in submitted information, please try again.</strong></h3>
                                <ul className="mb-0" style={{fontSize: '15px'}}>
                                    { validationErrors.map((validationError, index) => (
                                        <li key={index}>{ validationError }</li>
                                    ))}
                                </ul>
                            </div>
                        }

                        <div className="form-row">
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input
                                    label="Order"
                                    value={order}
                                    setter={setOrder}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Name"
                                    value={name}
                                    setter={setName}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Input 
                                    label="Permalink"
                                    value={permalink}
                                    setter={setPermalink}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <div>
                                    <input 
                                        type="checkbox" 
                                        checked={uploadImage}
                                        onChange={() => setUploadImage(!uploadImage)}
                                        id="input-upload_image" /> 
                                    <Form.Label className="mb-1 ml-2" htmlFor="input-upload_image">Upload Image</Form.Label>

                                    {uploadImage ? (
                                        <div className="float-right">
                                            <input 
                                                type="checkbox" 
                                                checked={useUrl}
                                                onChange={() => setUseUrl(!useUrl)}
                                                id="input-use_image_url" /> 
                                            <Form.Label className="mb-1 ml-2" htmlFor="input-use_image_url">(Use URL)</Form.Label>
                                        </div>
                                    ) : null}
                                </div>
                                {uploadImage ? (
                                    useUrl ? (
                                        <div id="div-image_url">
                                            <Form.Control 
                                                type="text" 
                                                value={imageUrl}
                                                onChange={ (e) => setImageUrl(e.target.value) }
                                                required={true}
                                            />
                                            {imageUrl && <img src={imageUrl} className="img-fluid mt-2" style={{backgroundColor: '#f3f3f3', padding: '5px', maxHeight: '100px',}}/> }
                                        </div>
                                    ) : (
                                        <div id="div-image_file">
                                            <input 
                                                type="file" 
                                                ref={fileRef}
                                                onChange={(e) => setImage(e.target.files[0])}
                                                name="image" 
                                                className="form-control" 
                                                accept="image/*" 
                                                required />
                                        </div>
                                    )
                                ) : null}
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Select 
                                    label="Show in Listing"
                                    value={showInListing}
                                    setter={setShowInListing}
                                    options={[
                                        {text: 'Yes', value: 'yes'},
                                        {text: 'No', value: 'no'},
                                    ]}
                                    required={true}
                                    />
                            </div>
                            <div className="col-xl-4 col-md-6 mb-3">
                                <Textarea
                                    label="Description"
                                    value={description}
                                    setter={setDescription}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <button 
                            type="submit" 
                            className="btn btn-secondary btn-submit w-100px"
                            disabled={isLoading}
                            >{ isLoading ? 'Loading...' : 'Submit' }</button>
                    </div>
                </div>
            </form>
        </div>
    );
}
 
export default Create;