import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Dropdown, Table } from 'react-bootstrap';
import DeletePopup from '../DeletePopup';

const Detail = () => {
    document.title = "Specs Mobile Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [specsItems, setSpecsItems] = useState([]);

    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/specs-mobiles/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
            setSpecsItems(response?.data?.details);
        }
    }, [response])
    
    return (
        <div className="container-fluid"> 
            {deleteItem ? <DeletePopup setItems={setSpecsItems} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Specs Mobile Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                        <Link to={`/specs-mobiles/edit/${id}`} className="mr-3 text-success">Edit</Link>
                        <a href="#" onClick={() => navigate(-1)}>Back</a>
                    </h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Brand</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.name}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Order</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.order}-{data?.order}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Name</label>
                                    <p className="font-weight-bold mb-0">{data?.name}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Permalink</label>
                                    <p className="font-weight-bold mb-0">{data?.permalink}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price</label>
                                    <p className="font-weight-bold mb-0">{data?.price}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-1">Image</label>
                                    <p className="font-weight-bold mb-0"><img src={data?.image} style={{maxHeight: '70px', maxWidth: '70px'}} /></p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-0">Description</label>
                                    <p className="font-weight-bold mb-0" dangerouslySetInnerHTML={{__html: data?.description_output}}></p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            {!isLoading && !error ? (
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Specs Items</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                            <Link to={`/specs-mobiles/detail/${id}/items/create`}>Add New</Link>
                        </h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        {specsItems?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{width: '100px'}}>Order</th>
                                        <th style={{minWidth: '100px'}}>Column 1</th>
                                        <th style={{minWidth: '100px'}}>Column 2</th>
                                        <th style={{minWidth: '100px'}}>Column 3</th>
                                        <th style={{width: '1px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { specsItems?.map((item) => (
                                        <tr key={ item.id }>
                                            <td>{ item.order }</td>
                                            <td dangerouslySetInnerHTML={{__html: item.column_1_output}}></td>
                                            <td dangerouslySetInnerHTML={{__html: item.column_2_output}}></td>
                                            <td dangerouslySetInnerHTML={{__html: item.column_3_output}}></td>
                                            <td style={{padding: '0.3rem'}}>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as={Link} to={`/specs-mobiles/detail/${id}/items/edit/${item.id}`}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'specs-mobiles/'+id+'/items/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
 
export default Detail;