import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import useFetch from './../../useFetch';

const Sidebar = () => {    
    const currentPath = useLocation().pathname.split('/')[1];
    
    const { response: pending } = useFetch(process.env.REACT_APP_BASE_API_URL+'/pending-count');
    
    return ( 
        <React.Fragment>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                <li className={`nav-item ${currentPath == '' ? 'active' : ''}`}><Link to="/" className="nav-link"><span>Dashboard</span></Link></li>
                {/* <li className={`nav-item ${currentPath == 'buy-phones' ? 'active' : ''}`}><Link to="/buy-phones" className="nav-link"><span>Buy Phone Ads</span></Link></li>
                <li className={`nav-item ${currentPath == 'imei' ? 'active' : ''}`}><Link to="/imei" className="nav-link"><span>IMEI</span></Link></li>
                <li className={`nav-item ${currentPath == 'orders' ? 'active' : ''}`}><Link to="/orders" className="nav-link"><span>Orders {pending?.order ? <span className="badge badge-secondary">{pending?.order}</span> : ''}</span></Link></li> */}
                <li className={`nav-item ${currentPath == 'instant-cash-requests' ? 'active' : ''}`}><Link to="/instant-cash-requests" className="nav-link"><span>Instant Cash Requests {pending?.instantCash ? <span className="badge badge-secondary">{pending?.instantCash}</span> : ''}</span></Link></li>
                {/* <li className={`nav-item ${currentPath == 'repair-requests' ? 'active' : ''}`}><Link to="/repair-requests" className="nav-link"><span>Repair Requests {pending?.repair ? <span className="badge badge-secondary">{pending?.repair}</span> : ''}</span></Link></li>
                <li className={`nav-item ${currentPath == 'contact-messages' ? 'active' : ''}`}><Link to="/contact-messages" className="nav-link"><span>Contact Messages {pending?.contactMessage ? <span className="badge badge-secondary">{pending?.contactMessage}</span> : ''}</span></Link></li>
                <li className={`nav-item ${currentPath == 'push-notifications' ? 'active' : ''}`}><Link to="/push-notifications" className="nav-link"><span>Push Notifications</span></Link></li>
                <li className={`nav-item ${currentPath == 'certified-options' ? 'active' : ''}`}><Link to="/certified-options" className="nav-link"><span>Certified Options</span></Link></li>
                <li className={`nav-item ${currentPath == 'certified-phones' ? 'active' : ''}`}><Link to="/certified-phones" className="nav-link"><span>Certified Phones</span></Link></li>
                <li className={`nav-item ${currentPath == 'accessories' ? 'active' : ''}`}><Link to="/accessories" className="nav-link"><span>Accessories</span></Link></li>
                <li className={`nav-item ${currentPath == 'specs-mobiles' ? 'active' : ''}`}><Link to="/specs-mobiles" className="nav-link"><span>Specs Mobiles</span></Link></li> */}
                <li className={`nav-item ${currentPath == 'instant-cash-mobiles' ? 'active' : ''}`}><Link to="/instant-cash-mobiles" className="nav-link"><span>Instant Cash Mobiles</span></Link></li>
                {/* <li className={`nav-item ${currentPath == 'repair-mobiles' ? 'active' : ''}`}><Link to="/repair-mobiles" className="nav-link"><span>Repair Mobiles</span></Link></li> */}
                {/* <li className={`nav-item ${currentPath == 'brands' ? 'active' : ''}`}><Link to="/brands" className="nav-link"><span>Brands</span></Link></li> */}
                {/* <li className={`nav-item ${currentPath == 'accessory-categories' ? 'active' : ''}`}><Link to="/accessory-categories" className="nav-link"><span>Accessory Categories</span></Link></li>
                <li className={`nav-item ${currentPath == 'banners' ? 'active' : ''}`}><Link to="/banners" className="nav-link"><span>Banners</span></Link></li> */}
            </ul>
        </React.Fragment>
     );
}
 
export default Sidebar;