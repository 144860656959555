import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Dropdown, Table } from 'react-bootstrap';
import DeletePopup from '../DeletePopup';

const Detail = () => {
    document.title = "Instant Cash Mobile Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [variants, setVariants] = useState([]);

    const [deleteItem, setDeleteItem] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/instant-cash-mobiles/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
            setVariants(response?.data?.variants);
        }
    }, [response])
    
    return (
        <div className="container-fluid"> 
            {deleteItem ? <DeletePopup setItems={setVariants} deleteItem={deleteItem} setDeleteItem={setDeleteItem} /> : null}
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Instant Cash Mobile Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                        <Link to={`/instant-cash-mobiles/edit/${id}`} className="mr-3 text-success">Edit</Link>
                        <a href="#" onClick={() => navigate(-1)}>Back</a>
                    </h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="form-row">
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Brand</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.name}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Order</label>
                                    <p className="font-weight-bold mb-0">{data?.brand?.order}-{data?.order}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Name</label>
                                    <p className="font-weight-bold mb-0">{data?.name}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Permalink</label>
                                    <p className="font-weight-bold mb-0">{data?.permalink}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price AT&T</label>
                                    <p className="font-weight-bold mb-0">{data?.price_at_t}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price Factory Unlocked</label>
                                    <p className="font-weight-bold mb-0">{data?.price_factory_unlocked}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price Sprint</label>
                                    <p className="font-weight-bold mb-0">{data?.price_sprint}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price T-Mobile</label>
                                    <p className="font-weight-bold mb-0">{data?.price_tmobile}</p>
                                </div>
                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price Verizon</label>
                                    <p className="font-weight-bold mb-0">{data?.price_verizon}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-1">Image</label>
                                    <p className="font-weight-bold mb-0"><img src={data?.image} style={{maxHeight: '70px', maxWidth: '70px'}} /></p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            {!isLoading && !error ? (
                <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Variants</h5>
                        <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                            <Link to={`/instant-cash-mobiles/detail/${id}/variants/create`}>Add New</Link>
                        </h6>
                        <div className="clearfix"></div>
                    </div>
                    <div className="card-body pb-2">
                        {variants?.length == 0 ? <h5 className="text-center text-danger">No data found</h5> : (
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{width: '100px'}}>Order</th>
                                        <th style={{minWidth: '100px'}}>Name</th>
                                        <th style={{minWidth: '100px'}}>Price</th>
                                        <th style={{width: '1px' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { variants?.map((item) => (
                                        <tr key={ item.id }>
                                            <td>{ item.order }</td>
                                            <td>{ item.name }</td>
                                            <td>
                                                { item.price_at_t ? (<span>AT&T: <b>{item.price_at_t}</b>, </span>) : ''}
                                                { item.price_factory_unlocked ? (<span>Factory Unlocked: <b>{item.price_factory_unlocked}</b>, </span>) : ''}
                                                { item.price_sprint ? (<span>Sprint: <b>{item.price_sprint}</b>, </span>) : ''}
                                                { item.price_tmobile ? (<span>T-Mobile: <b>{item.price_tmobile}</b>, </span>) : ''}
                                                { item.price_verizon ? (<span>Verizon: <b>{item.price_verizon}</b></span>) : ''}
                                            </td>
                                            <td style={{padding: '0.3rem'}}>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="secondary" className="btn-sm" id="dropdown-basic">Actions</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as={Link} to={`/instant-cash-mobiles/detail/${id}/variants/edit/${item.id}`}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setDeleteItem({id: item.id, url: 'instant-cash-mobiles/'+id+'/variants/'+item.id})} className="text-danger">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
 
export default Detail;