import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate, Link } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Button } from 'react-bootstrap';

const Detail = () => {
    document.title = "Accessory Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();

    const [data, setData] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/accessories/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
        }
    }, [response])
    
    return (
        <div className="container-fluid"> 
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Accessory Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right">
                        <Link to={`/accessories/edit/${id}`} className="mr-3 text-success">Edit</Link>
                        <a href="#" onClick={() => navigate(-1)}>Back</a>
                    </h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <div className="form-row">                                
                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Posted Date</label>
                                    <p className="font-weight-bold mb-0">{data?.created_at}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Title</label>
                                    <p className="font-weight-bold mb-0">{data?.title}</p>
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Price</label>
                                    <p className="font-weight-bold mb-0">Rs. {data?.price}</p>
                                    {data?.sold_at ? (<div className="badge badge-success d-inline-block mt-1" style={{whiteSpace: 'initial', lineHeight: '1.2'}}>Sold: {data?.sold_at}</div>) : null}
                                </div>

                                <div className="col-xl-4 col-md-6 mb-3">
                                    <label className="mb-0">Category</label>
                                    <p className="font-weight-bold mb-0">{data?.category?.name}</p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-0">Description</label>
                                    <p className="font-weight-bold mb-0" dangerouslySetInnerHTML={{__html: data?.description_output}}></p>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="mb-1">Images</label>
                                    <div className="row no-gutters mx-n2 row-cols-xl-7 row-cols-lg-5 row-cols-md-4 row-cols-sm-3 row-cols-2">
                                        {data?.images?.map(image => (
                                            <div key={image} className="col mb-2 px-2 custom-file-container-preview position-relative">
                                                <div className="custom-file-container-preview-item">
                                                    <img src={image} className="img-fluid"/>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Detail;