import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useNavigate } from "react-router-dom";
import useFetch from '../../../useFetch';
import { Button } from 'react-bootstrap';

const Detail = () => {
    document.title = "Contact Message Details | CellyCash";
    
    const { id } = useParams();
    let navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [successStatus, setSuccessStatus] = useState(null);
    const [errorStatus, setErrorStatus] = useState(null);

    const { response, isLoading, error } = useFetch(process.env.REACT_APP_BASE_API_URL+'/contact-messages/'+id);

    useEffect(() => {
        if(response?.data)
        {
            setData(response?.data);
        }
    }, [response])

    const handleStatus = (e, status) => {
        e.preventDefault();

        setIsLoadingStatus(true);
        setSuccessStatus(null);
        setErrorStatus(null);

        fetch(process.env.REACT_APP_BASE_API_URL+'/contact-messages/'+id+'/change-status/'+status, {
            method: 'POST',
            headers: { "Authorization": "Bearer "+localStorage.getItem("token") }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while changing status, please try again.');
            }

            return response.json();
        })
        .then(data => {
            setIsLoadingStatus(false);

            if (data?.status == 'success')
            {
                setSuccessStatus(data.message);
                setData(data.data);
            }
            else
            {
                setErrorStatus(data.message);
            }
        })
        .catch(error => {
            setIsLoadingStatus(false);
            setErrorStatus(error.message);
        })
    }
    
    return (
        <div className="container-fluid"> 
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex">
                    <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Contact Message Details</h5>
                    <h6 className="m-0 font-weight-bold text-primary ml-auto align-self-center text-right"><a href="#" onClick={() => navigate(-1)}>Back</a></h6>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body pb-2">
                    {isLoading ? (
                        <div className="text-center py-4">
                            <FontAwesomeIcon icon="spinner" size="4x" spin={true} />
                            <h5 className="text-center mt-3">Loading Data</h5>
                        </div>
                    ) : (
                        error ? <div className="alert-msg-danger"><h3 className="mb-0"><strong>{ error }</strong></h3></div> : (
                            <>
                                <div className="mb-2">
                                    <label className="mb-1">Change Status</label>
                                    <p className="font-weight-bold mb-0">
                                        <Button variant="warning" onClick={(e) => handleStatus(e, 'pending')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Pending</Button>
                                        <Button variant="primary" onClick={(e) => handleStatus(e, 'On Hold')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">On Hold</Button>
                                        <Button variant="info" onClick={(e) => handleStatus(e, 'In Progress')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">In Progress</Button>
                                        <Button variant="success" onClick={(e) => handleStatus(e, 'Answered')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Answered</Button>
                                        <Button variant="danger" onClick={(e) => handleStatus(e, 'Fake')} disabled={isLoadingStatus} className="btn btn-sm mr-1 mb-2">Fake</Button>
                                    </p>
                                    { successStatus && <div className="alert-msg-success flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ successStatus }</strong></h3></div> }
                                    { errorStatus && <div className="alert-msg-danger flex-fill mt-0 mb-3"><h3 className="mb-0"><strong>{ errorStatus }</strong></h3></div> }
                                </div>

                                <div className="form-row">
                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <label className="mb-0 d-block">Status</label>
                                        <span className={`badge badge-table badge-${data?.status_badge} mr-3 font-weight-bold`}>{ data?.status }</span>
                                        {data?.status_changed_at ? (<><br /><div className="badge badge-success d-inline-block mt-1" style={{whiteSpace: 'initial', lineHeight: '1.2'}}>Changed: {data?.status_changed_at}</div></>) : null}
                                    </div>

                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <label className="mb-0">Posted Date</label>
                                        <p className="font-weight-bold mb-0">{data?.created_at}</p>
                                    </div>

                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <label className="mb-0">User Details</label>
                                        <p className="font-weight-bold mb-0">{data?.name}</p>
                                        {data?.phone ? <p className="font-weight-bold mb-0"><a href={`tel:${data?.phone}`}>{data?.phone}</a></p> : null}
                                        {data?.email ? <p className="font-weight-bold mb-0"><a href={`mailto:${data?.email}`}>{data?.email}</a></p> : null}
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label className="mb-0">Message</label>
                                        <p className="font-weight-bold mb-2">Subject: {data?.subject}</p>
                                        <p className="font-weight-bold mb-0" dangerouslySetInnerHTML={{__html: data?.message}}></p>
                                    </div>

                                    <div className="col-xl-4 col-md-6 mb-3">
                                        <label className="mb-0">User</label>
                                        {data?.user ? (
                                            <>
                                                <p className="font-weight-bold mb-0">{data?.user.name}</p>
                                                {data?.user?.phone ? <p className="font-weight-bold mb-0"><a href={`tel:${data?.user?.phone}`}>{data?.user?.phone}</a></p> : null}
                                                {data?.user?.email ? <p className="font-weight-bold mb-0"><a href={`mailto:${data?.user?.email}`}>{data?.user?.email}</a></p> : null}
                                            </>
                                        ) : (
                                            <p className="font-weight-bold mb-0 text-danger">GUEST</p>
                                        )}
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Detail;