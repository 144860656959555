import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

const NavbarAdmin = ({sidebarToggled, setSidebarToggled, removeToken}) => {
    const handleLogout = () => {
        fetch(process.env.REACT_APP_BASE_API_URL+'/logout', {
            method: 'POST',
            headers: { 
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem("token")
            },
        })

        removeToken();
    }

    return ( 
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-2" onClick={() => setSidebarToggled(!sidebarToggled)}>
                <FontAwesomeIcon icon="bars" />
            </button>
            <Navbar.Brand href="#">
                <img src="https://cellycash.com/images/logo.svg"/>
            </Navbar.Brand>
            <ul className="navbar-nav ml-auto">
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </ul>
        </nav>
     );
}
 
export default NavbarAdmin;